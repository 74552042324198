import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Interview: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg width="48px" height="48px" viewBox="0 0 48 48" className={clss}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M27.97 14.024c-2.542 0-4.89.705-6.766 1.917a.67.67 0 0 0 .214 1.215c.17.038.349.005.497-.09 1.652-1.068 3.756-1.708 6.055-1.708 5.44 0 9.712 3.57 9.712 7.833 0 2.957-1.905 5.38-4.985 6.74a.669.669 0 0 0-.36.812l.443 1.458-2.637-1.114a.647.647 0 0 0-.247-.052H27.97c-1.03 0-2.024-.124-2.955-.365 1.703-1.345 2.78-3.258 2.78-5.416 0-4.193-4.055-7.448-8.897-7.448-4.843 0-8.898 3.255-8.898 7.448 0 2.686 1.686 4.884 4.15 6.166l-.525 1.74a.673.673 0 0 0 .187.693c.19.173.462.22.699.12l2.997-1.272h1.39c1.695 0 3.293-.395 4.655-1.093 1.355.492 2.847.76 4.417.76h1.792l3.8 1.604c.237.1.509.054.7-.12a.673.673 0 0 0 .186-.692l-.7-2.313C36.876 29.273 39 26.54 39 23.191c0-5.15-5.013-9.167-11.03-9.167zm-9.072 5.115c4.263 0 7.58 2.806 7.58 6.115 0 3.308-3.317 6.114-7.58 6.114h-1.524a.646.646 0 0 0-.258.052l-1.823.771.268-.885a.67.67 0 0 0-.36-.802c-2.411-1.068-3.883-2.953-3.883-5.25 0-3.309 3.316-6.115 7.58-6.115zm-3.47 5.115a.991.991 0 0 0-.99.99c0 .546.447.989.99.989a.983.983 0 0 0 .978-.99.983.983 0 0 0-.979-.99zm3.47 0a.983.983 0 0 0-.979.99c0 .546.437.989.979.989a.983.983 0 0 0 .978-.99.983.983 0 0 0-.978-.99zm3.47 0a.983.983 0 0 0-.978.99c0 .546.436.989.978.989a.983.983 0 0 0 .978-.99.983.983 0 0 0-.978-.99z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default Interview;
